import request from "../utils/request";

export function get_store_promotion_list(data: JSON){
    return request({
        url: "/getStorePromotionList",
        method: "post",
        data: data
    });
}

export function get_store_promotion_detail(data: JSON){
    return request({
        url: "/getStorePromotionDetail",
        method: "post",
        data: data
    });
}

export function save_store_promotion_detail(data: JSON){
    return request({
        url: "/saveStorePromotionDetail",
        method: "post",
        data: data
    });
}

export function delete_store_promotion(data: JSON) {
    return request({
        url: "/deleteStorePromotion",
        method: "post",
        data: data
    });
}